import { Nav } from 'react-bootstrap';
import { Link } from "react-router-dom";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faLinkedin } from '@fortawesome/free-brands-svg-icons';

import Logo from '../../../assets/images/logo-icon.png'

import './Footer.css';

const Footer = () => {
    return (
        <div className='footer py-4'>
            <Row className='align-items-center justify-content-center mx-0'>
                <Col sm={"auto"} className="footer-width align-items-center justify-content-center">
                    <div className='m-auto width-block'>
                        <h4 className='footer-title'>USEFUL LINKS</h4>
                        <Nav>
                            <div className="social-links">
                                <Link className='link-footer me-3' to="/">Home</Link>
                                {/* <Link className='link-footer me-3' to="/catalog">Catalog</Link> */}
                                <Link className='link-footer' to="/FAQ">FAQ</Link>
                            </div>
                        </Nav>
                    </div>
                </Col>

                <Col sm={"auto"} className="footer-width align-items-center justify-content-center">
                    <div className='m-auto width-block'>
                        <h4 className='footer-title'>CONTACT</h4>
                        <Nav>
                            <div className="social-links">
                                <a className='link-footer social-icon' href="https://www.facebook.com/cloudorderus" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faFacebook} /> Facebook
                                </a>
                                <a className='link-footer social-icon' href="https://www.linkedin.com/company/cloudorderus/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                                </a>
                                <a className='link-footer me-3 social-icon' href="mailto:info@cloudorder.us">info@cloudorder.us</a>
                            </div>
                        </Nav>
                    </div>
                </Col>

                <Col sm={"auto"} className='footer-width d-flex align-items-center justify-content-center'>

                    <div className="social-links">
                        <div>
                            <span><img className='footer-logo' src={Logo} alt="logo" /></span>
                            <div className='footer-system'>© CloudOrder.us System.</div>
                        </div>
                        <div>
                            <Link className='link-footer' to="/privacy-policy">Privacy Policy</Link>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
}
export default Footer;