import { FC, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga'

import Loader from '../../../components/common/Loader/Loader';

import about1 from '../../../assets/images/about_1.png'
import about2 from '../../../assets/images/about_2.png'
import about3 from '../../../assets/images/about_3.png'
import LogoGray from '../../../assets/images/cloud-order-gray.png'
import promotion1 from '../../../assets/images/promotion_1.png'
import promotion2 from '../../../assets/images/promotion_2.png'
import founderImage from '../../../assets/images/businessprofile-ZhenSmall.jpg'

import YouTubePlayer from '../../../components/common/YouTubePlayer/YouTubePlayer';

import "./Home.css"

type THomeProps = {
    isLoading?: boolean,
}

const Home: FC<THomeProps> = ({ isLoading }) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])

    if (isLoading) {
        return (
            <Loader />
        )
    }

    return (
        <div>
            <section className='banner'>
                <Container className='section-wrapper'>
                    <h2 className="banner-headline">Your</h2>
                    <h1 className="banner-headline">WHOLESALE MARKETPLACE</h1>
                    <h2 className="banner-title text-center mt-4">For Dropshipping</h2>
                    {/* <Link to="/register" className="gradient-btn gradient-xl mt-5">JOIN US NOW</Link> */}
                </Container>
            </section>

            <section className='about-us'>
                <Container className='section-wrapper'>
                    <h2 className="about-headline mt-5">What We Do</h2>
                    <h6 className="about-title mt-4 max-width-785">We are an E-Commerce marketplace that connects retailers with brand suppliers, dropshipping style.</h6>
                    <p className="about-description max-width-785 mt-4">Retail stores focus on marketing. CloudOrder takes care of the logistics.<br />Brands focus on product development. CloudOrder takes care of marketing and traffic.</p>
                    <div className='w-100 d-flex flex-column align-items-center mt-5'>

                        <div className="about-row mb-5">
                            <div className="about-block me-5">
                                <div className="about-wrapper">
                                    <img src={about1} alt='' />
                                    <h6 className="about-bllock-title">Expand Sales Network <br />
                                        for Brands</h6>
                                    <p className="about-bllock-desc mb-0">Brands have access to thousands <br /> of retail stores as part of their sales
                                        network.</p>
                                </div>
                            </div>
                            <div className="about-img-block">
                                <img src={LogoGray} alt='' />
                            </div>
                            <div className="about-block ms-5">
                                <div className="about-wrapper">
                                    <img src={about3} alt='' />
                                    <h6 className="about-bllock-title">Dropshipping Source for Retailers</h6>
                                    <p className="about-bllock-desc">Shopify sellers import products from thousands of small to medium FBA sellers and dropship to their customers.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="about-block">
                            <div className="about-wrapper">
                                <img src={about2} alt='' />
                                <h6 className="about-bllock-title">E-Commerce Wholesale <br /> Marketplace</h6>
                                <p className="about-bllock-desc mb-0">We automate the process to connect retailers with brands; So, FBA sellers focus on products
                                    and stores focus on marketing.</p>
                            </div>
                        </div>

                    </div>
                </Container>
            </section>

            <section className='product-promotion'>
                <Container className='section-wrapper'>
                    <h2 className="promotion-headline mt-5">Who can use CloudOrder?</h2>
                    <Container className='section-wrapper flex-row align-items-start product-promotion-grid'>
                        <div className="promotion-wrapper">
                            <div className="promotion-block ">
                                <h6 className="promotion-block-title mx-4">For Shopify Stores</h6>
                                <YouTubePlayer videoId="C4UsYfLj8rc" />
                                <h6 className="promotion-block-subtitle mx-4 mt-3">Web stores to dropship products from small
                                    brands, that are tested and proven by customers
                                    worldwide.</h6>
                                <p className="promotion-bllock-desc mx-4 mt-3">Join our community and get involved, your feedback is always more
                                    than welcome!
                                </p>
                                {/* <Link to="/register" className="gradient-btn gradient-xl mt-5 mx-4 promotion-btn">JOIN US NOW</Link> */}
                            </div>
                        </div>

                        <div className="promotion-wrapper">
                            <div className="promotion-block">
                                <h6 className="promotion-block-title mx-4">For Amazon Sellers</h6>
                                <YouTubePlayer videoId="yCSR0adT11k" />
                                <h6 className="promotion-block-subtitle mx-4 mt-3">Amazon Sellers expand their sales channels by making their products
                                    available for dropshipping on CloudOrder.</h6>
                                <p className="promotion-bllock-desc mx-4 mt-3">Join our community and get involved, your feedback is always more
                                    than welcome!
                                </p>
                                {/* <Link to="/register" className="gradient-btn gradient-xl mt-5 mx-4 promotion-btn">JOIN US NOW</Link> */}
                                {/* <form method="post" enctype="multipart/form-data" id="supplierForm" data-hs-cf-bound="true">
                                <input type="hidden" name="csrfmiddlewaretoken" value="YUk1FJ1RIPqpuhHdr6x469d7ItS4LCw12cGW6kD2krcFewBUq8UnPPb6GdIj5gHa">
                                <input type="hidden" value="FBA Supplier" name="FBA Supplier">
                                <a class="gradient-btn gradient-xl mt-5 mx-4" href="javascript: submitSupplier()">JOIN US NOW</a>
                            </form> */}
                            </div>
                        </div>
                    </Container>
                </Container>
            </section>

            <section className='our-story'>
                <Container className='section-wrapper'>
                    <h2 className="story-headline mt-5">Our Story</h2>
                    <div className='w-100 d-flex flex-column align-items-center mt-5'>
                        <div className="about-row mb-5">
                            <div className="story-image-block ms-5">
                                <img src={founderImage} alt='' />
                            </div>
                            <div className="story-block me-5">
                                <p className="story-description max-width-785 mt-4">As a successful Amazon seller, CloudOrder founder, Zhen "James" Chen, realized the bottle neck and restrictions imposed by being a seller solely based on Amazon. </p>
                                <p className="story-description max-width-785 mt-4">
                                    Being a small brand owner, we did not have a big marketing budget to build a sales network. We realized that small brands need an online wholesale marketplace in order to expand their businesses beyond Amazon. </p>
                                <p className="story-description-emph max-width-785 mt-4">
                                    As a result, CloudOrder was born.
                                </p>
                            </div>
                        </div>
                    </div>
                </Container>
            </section>


            {/* <section className='quote-area'>
                <Container className='d-flex flex-column align-items-center justify-content-center'>
                    <h1 className="quote-area-title text-center">Ready to start?</h1>
                    <Link to="/register" className="gradient-btn gradient-xl mt-5">GIVE US A TRY</Link>
                </Container>
            </section> */}
        </div>
    );
}
export default Home;